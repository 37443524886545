<template>
    <div>
        <b-sidebar backdrop id="add_genexpense" aria-labelledby="sidebar-no-header-title" no-header width="90rem" style="direction:ltr" right title="مصروفات عامة" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl"> 
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{$parent.lang.add_general_expense}}</span>
        </div>
        <div @click="hide" id="closeme" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{$parent.lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.billno}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.billno"
                            v-model="bill_number"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="bill_date"
                            width="290px"
                            persistent
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <label>{{$parent.lang.bill_date}}</label>
                                <b-form-input class="inborder"
                                v-model="bill_date"
                                :label="$parent.lang.date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                ></b-form-input>
                            </template>
                            <v-date-picker
                                v-model="bill_date"
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                                >
                                Cancel
                                </v-btn>
                                <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(bill_date)"
                                >
                                OK
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.vendorid}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.vendorid"
                            v-model="vendorid"
                            @change="vendorCheck()"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{$parent.lang.vendor_name}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.vendor_name"
                            v-model="vendor"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.mobile}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.mobile"
                            v-model="mobile"
                            @change="vendorCheck()"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.vendor_vatids}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.vendor_vatids"
                            v-model="vat_number"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{$parent.lang.vendor_address}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.vendor_address"
                            v-model="vendor_address"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <label>{{$parent.lang.invoice_notes}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.invoice_notes"
                            v-model="inv_notes"
                        ></b-form-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.payment_method}}</label>
                        <b-input-group>
                            <b-form-select class="selborder"
                                v-model="paytype"
                                :options="paytypes"
                                :label="$parent.lang.payment_method"
                            ></b-form-select>
                            <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.vat_type}}</label>
                        <b-input-group>
                            <b-form-select class="selborder"
                            v-model="vattype"
                            :options="vattypes"
                            :label="$parent.lang.vat_type "
                            @change="recalkAll()"
                        ></b-form-select>
                            <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.type}}</label>
                        <b-input-group>
                            <b-form-select class="selborder"
                            v-model="invtype"
                            :options="invtypes"
                            :label="$parent.lang.type"
                            @change="recalkAll()"
                        ></b-form-select>
                            <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.bill_type}}</label>
                        <b-input-group>
                            <b-form-select class="selborder"
                            v-model="cashajl"
                            :options="cashajls"
                            :label="$parent.lang.bill_type"
                            @change="recalkAll()"
                            ></b-form-select>
                            <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{$parent.lang.expeneses_type}}</label>
                        <b-input-group>
                            <b-form-select class="selborder"
                            v-model="expeneses_type"
                            :options="expenesesTypes"
                            :label="$parent.lang.expeneses_type"
                            ></b-form-select>
                            <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                    </v-col>
                    
                </v-row>
                <v-row>
                    <v-window>{{$parent.lang.bill_detates}}</v-window>
                    <v-simple-table striped hover style="width:100%;">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">{{$parent.lang.item_code}}</th>
                                    <th class="text-center">{{$parent.lang.item_name}}</th>
                                    <th class="text-center">{{$parent.lang.item_unit}}</th>
                                    <th class="text-center">{{$parent.lang.qtty}}</th>
                                    <th class="text-center">{{$parent.lang.item_price}}</th>
                                    <th class="text-center">{{$parent.lang.total}}</th>
                                    <th class="text-center">{{$parent.lang.vat}}</th>
                                    <th class="text-center">{{$parent.lang.ftotal}}</th>
                                    <th class="text-center">{{$parent.lang.action}}</th>
                                </tr>
                            </thead>
                            <tbody id="tablerow">
                                <tr v-for="(item,index) in tbrows" :key="index">
                                    <td style="width:">{{ item.item_number }}</td>
                                    <td>{{ item.item_name }}</td>
                                    <td>{{ item.unit }}</td>
                                    <td>{{ item.qty }}</td>
                                    <td>{{ item.item_price }}</td>
                                    <td>{{ item.total }}</td>
                                    <td>{{ item.vat }}</td>
                                    <td>{{ item.ftotal }}</td>
                                    <td>
                                        <b-button type="button" variant="danger" class="btn btn-sm" style="width:65px" @click="removeItem(index)">{{$parent.lang.delete}}</b-button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.item_number"
                                            ref="item_number"
                                            @change="getItems()"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.item_name"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.unit"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.qty"
                                            @change="calcKit()"
                                            type="number"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.item_price"
                                            @change="calcKit()"
                                            type="number"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.total"
                                            @change="calcKit()"
                                            type="number"
                                            readonly
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.vat"
                                            @change="calcKit()"
                                            type="number"
                                            readonly
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                            v-model="newrow.ftotal"
                                            @change="calcKit()"
                                            type="number"
                                            readonly
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <b-button type="button" class="btn-sm" variant="success" @click="addNewRow()">{{$parent.lang.add}}</b-button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="text-center"></th>
                                    <th class="text-center"></th>
                                    <th class="text-center"></th>
                                    <th class="text-center"></th>
                                    <th class="text-center">{{$parent.lang.totals}}</th>
                                    <th class="text-center">{{ full_total }}</th>
                                    <th class="text-center">{{ full_vat }}</th>
                                    <th class="text-center">{{ full_ftotal }}</th>
                                    <th class="text-center"></th>
                                </tr>
                            </tfoot>
                            
                        </template>
                    </v-simple-table>
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" id="addPurch" variant="success" @click='addPurchase()' class="ma-2" style="width:100px;">{{ SUBMIT_NAME }}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            vendorid: '',
            bill_number:'',
            modal: false,
            vendor: '',
            mobile: '',
            bill_date: '',
            vat_number: '',
            vendor_address: '',
            paytype: 1,
            vattype: 2,
            full_total:0,
            full_vat:0,
            full_ftotal:0,
            invtype:2,
            inv_notes:'',
            tbrows:[],
            getExpense: '',
            expeneses_type: 1,
            expenesesTypes: [],
            newrow:{
                item_number: '',
                item_name: '',
                unit: this.$parent.lang.pace,
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            },
            paytypes:[
                {text: this.$parent.lang.cash_payment, value: 1},
                {text: this.$parent.lang.bank_payment, value: 2},
            ],
            vattypes:[
                {text: this.$parent.lang.prices_without_vat, value: 1},
                {text: this.$parent.lang.prices_exclusive_vat, value: 2},
                {text: this.$parent.lang.prices_include_vat, value: 3},
            ],
            invtypes:[
                {text: this.$parent.lang.expenses, value: 2},
            ],
            cashajl: 1,
            cashajls:[
                {text: this.$parent.lang.paid_invoice, value: 1},
                {text: this.$parent.lang.none_paid_invoice, value: 2},
            ],
            SUBMIT_NAME:this.$parent.lang.add
        }
    },
    methods:{
        getExpType(){
            const post = new FormData();
            post.append("type","getExpType");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[type]',this.getExpense);
            let myar = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.length != 0){
                    for(let i=0;i<res.results.data.length;i++){
                        myar.push(
                            {
                                text: (this.$parent.lang.langname == "ar") ? res.results.data[i].namear : res.results.data[i].namear, 
                                value: res.results.data[i].id,
                            }
                        );
                    }
                }
                this.expenesesTypes = myar;
                this.expeneses_type = res.results.data[0].classid;
            })
        },
        getPayTypes() {
            const post = new FormData();
            post.append("type","getPayTypes");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]',1);
            let myar = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.length != 0){
                    for(let i=0;i<res.results.data.length;i++){
                        myar.push(
                            {
                                text: (this.$parent.lang.langname == "ar") ? res.results.data[i].type_name : res.results.data[i].type_name_en, 
                                value: res.results.data[i].id,
                                typeid: res.results.data[i].typeid,
                            }
                        );
                    }
                }
                this.paytypes = myar;
                this.paytype = res.results.data[0].id;
            })
        },
        vendorCheck() {
            const post = new FormData();
            post.append("type","getVendor");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[vendorid]',this.vendorid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(typeof response.data.results !== 'undefined' 
                    && typeof response.data.results.data !== 'undefined'
                    && response.data.results.data.length != 0){
                        this.vendor = response.data.results.data.full_name;
                        this.vat_number = response.data.results.data.vatid;
                        this.vendor_address = response.data.results.data.address;
                        this.mobile = response.data.results.data.mobile;
                    }
            })
        },
        getItems() {
            const post = new FormData();
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[item_number]',this.newrow.item_number);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0){
                    this.newrow.item_number = response.data.results.data[0].itemcode;
                    this.newrow.item_name = response.data.results.data[0].name;
                    this.newrow.item_price = response.data.results.data[0].price;
                }
                this.calcKit();
            })
        },
        calcTotals(){
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;  
            for(let i = 0; i< this.tbrows.length;i++){
                this.full_total = +this.full_total + +this.tbrows[i].total;
                this.full_vat = +this.full_vat + +this.tbrows[i].vat;
                this.full_ftotal = +this.full_ftotal + +this.tbrows[i].ftotal;
            }
        },
        recalkAll(){
            let newval = [];
            const vt = this.vattype;
            for(let i = 0;i < this.tbrows.length;i++){
                let currow = this.tbrows[i];
                let qty = currow.qty;
                let price = currow.item_price;
                let total = 0;
                let vat = 0;
                let ftotal = 0;
                currow.total = 0;
                currow.vat = 0;
                currow.ftotal = 0;
                if(qty != '' && price != ''){
                    total = +qty * +price;
                    const cal = this.$calcVat(total,vt);
                    total = cal.tot;
                    vat = cal.vat;
                    ftotal = cal.ftot;
                }
                currow.total = total;
                currow.vat = vat;
                currow.ftotal = ftotal;
                newval.push(currow)
            }
            this.tbrows = newval;
            this.calcTotals();
        },
        calcKit(){
            const vt = this.vattype;
            const qty = this.newrow.qty;
            const price = this.newrow.item_price;
            let total = 0;
            let vat = 0;
            let ftotal = 0;
            this.newrow.total = 0;
            this.newrow.vat = 0;
            this.newrow.ftotal = 0;
            if(qty != '' && price != ''){
                total = +qty * +price;
                const cal = this.$calcVat(total,vt);
                total = cal.tot;
                vat = cal.vat;
                ftotal = cal.ftot;
            }
            this.newrow.total = total;
            this.newrow.vat = vat;
            this.newrow.ftotal = ftotal;
            // console.log(this.newrow)
        },
        addNewRow(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.newrow.qty == 0 || this.newrow.qty == ''){
                this.$snotify.error('الكمية يجب انت تكون رقم غير الصفر', 'خطــأ', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }
            // if(this.newrow.item_number == ''){
            //     this.$snotify.error('لا يمكن اضافة صنف بدون رقم الصنف', 'خطــأ', {
            //         timeout: 2000,
            //         showProgressBar: true,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //     });
            //     return false;
            // }
            if(this.newrow.item_name == ''){
                this.$snotify.error('لا يمكن اضافة صنف بدون اسم', 'خطــأ', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }
            if(this.newrow.item_price == ''){
                this.$snotify.error('يجب أن يكون سعر الصنف رقما غير صفر', 'خطــأ', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }
            
            this.tbrows.push(this.newrow);
            this.newrow = {
                item_number: '',
                item_name: '',
                unit: this.$parent.lang.pace,
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            }
            // console.log(this.newrow);
            this.$refs.item_number.focus();
            this.calcTotals();
        },
        removeItem(index){
            this.tbrows.splice(index, 1);
        },
        addPurchase()
        {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.buill_date == ''){ 
                if(this.bill_date == ''){
                    this.$snotify.error('يرجى اختيار تاريخ الفاتورة', 'خطــأ', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                }
                return false;
            }
            if(this.tbrows.length < 1){
                this.$snotify.error('لا يمكن ادخال فاتورة فارغة', 'خطــأ', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }
            document.getElementById('addPurch').disabled = true;
            const post = new FormData();
            post.append("type", 'createBillExp');
            post.append("auth", this.$cookies.get(this.$COOKIEPhase));
            post.append('data[bill][bill_number]',this.bill_number);
            post.append('data[bill][vendorid]',this.vendorid);
            post.append('data[bill][vendor]',this.vendor);
            post.append('data[bill][mobile]',this.mobile);
            post.append('data[bill][bill_date]',this.bill_date);
            post.append('data[bill][vat_number]',this.vat_number);
            post.append('data[bill][vendor_address]',this.vendor_address);
            post.append('data[bill][paytype]',this.paytype);
            post.append('data[bill][vattype]',this.vattype);
            post.append('data[bill][inv_notes]',this.inv_notes);
            post.append('data[bill][invtype]',this.invtype);
            post.append('data[bill][total]',this.full_total);
            post.append('data[bill][vat]',this.full_vat);
            post.append('data[bill][ftotal]',this.full_ftotal);
            post.append('data[bill][cashajl]',this.cashajl);
            post.append('data[bill][expeneses_type]',this.expeneses_type);
            for(let i=0;i<this.tbrows.length;i++ ){
                for(var key in this.tbrows[i]){
                    post.append('data[rows]['+i+']['+key+']',this.tbrows[i][key]);
                }
            }
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.$snotify.success('تمت الاضافة بنجاح', '', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                this.vendor = '';
                this.mobile = '';
                this.bill_date = '';
                this.vat_number = '';
                this.vendor_address = '';
                this.paytype = 1;
                this.vattype = 2;
                this.full_total = 0;
                this.full_vat = 0;
                this.full_ftotal = 0;
                this.invtype = 1;
                this.inv_notes = '';
                this.tbrows = [];
                this.$parent.getInvoice();
                document.getElementById('closeme').click();
            });
        }
    },
    created() {
       //this.addNewRow()
       this.getPayTypes();
       this.getExpType();
    },
}
</script>
